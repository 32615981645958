// axios
import axios from 'axios'
import Vue from 'vue'
import appConfig from '@/config/appConfig'

const axiosIns = axios.create({
  baseURL: appConfig.apiURL,
})

axiosIns.interceptors.request.use(
  config => {
    // Do something before request is sent

    const accessToken = localStorage.getItem('accessToken')

    // eslint-disable-next-line no-param-reassign
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

axiosIns.interceptors.response.use(request => request,
  error => {
    if (error.response.status === 401) {
      localStorage.removeItem('accessToken')
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')
    }

    return Promise.reject(error)
  })

Vue.prototype.$http = axiosIns

export default axiosIns
