import {
  mdiAccountOutline,
  mdiCalendarOutline,
  mdiChartBoxOutline,
  mdiFileOutline,
  mdiFormatListCheckbox,
  mdiHomeOutline,
  mdiNotebookCheckOutline,
  mdiOfficeBuildingOutline,
  mdiPrinter3dNozzleOutline,
  mdiWarehouse,
  mdiPackage,
} from '@mdi/js'

export default [
  {
    title: 'Dashboard',
    icon: mdiHomeOutline,
    to: 'admin-dashboard',
  },
  {
    title: 'Warrants',
    icon: mdiFileOutline,
    to: 'admin-warrant-list',
    children: [
      {
        title: 'Warrant list',
        to: 'admin-warrant-list',
      },
      {
        title: 'By post',
        to: 'admin-warrant-list-by-post',
      },
      {
        title: 'For invoicing',
        to: 'admin-warrant-list-by-invoice',
      },
      {
        title: 'Create warrant',
        to: 'admin-warrant-create',
      },
    ],
  },
  {
    title: 'Companies',
    icon: mdiOfficeBuildingOutline,
    to: 'admin-company-list',
  },
  {
    title: 'Equipment Management',
    icon: mdiPrinter3dNozzleOutline,
    to: 'admin-equipment-list',
  },
  {
    title: 'Stock',
    icon: mdiWarehouse,
    to: 'admin-stock-list',
    children: [
      {
        title: 'Stock',
        to: 'admin-stock-list',
      },
      {
        title: 'Material categories',
        to: 'admin-stock-category-list',
      },
    ],
  },
  {
    title: 'Items',
    icon: mdiPackage,
    to: 'admin-item-list',
  },
  {
    title: 'Todo',
    icon: mdiFormatListCheckbox,
    to: 'admin-todo-list',
  },
  {
    title: 'Journal',
    icon: mdiNotebookCheckOutline,
    to: 'admin-diary-list',
  },
  {
    title: 'Planner',
    icon: mdiCalendarOutline,
    to: 'admin-planner-list',
  },
  {
    title: 'User Management',
    icon: mdiAccountOutline,
    children: [
      {
        title: 'User List',
        to: 'admin-user-list',
      },
      {
        title: 'Add User',
        to: 'admin-user-create',
      },
    ],
  },
  {
    title: 'Reports',
    icon: mdiChartBoxOutline,
    children: [
      {
        title: 'Daily income',
        to: 'admin-daily-income',
      },

      // {
      //   title: 'Employees report',
      //   to: 'admin-employees-report',
      // },
      // {
      //   title: 'Warrants report',
      //   to: 'admin-warrants-report',
      // },
      {
        title: 'Sales analytic',
        to: 'admin-sales-analytic',
      },
    ],
  },
]
