<template>
  <div
    class="vertical-nav-menu-container"
    @mouseenter="isMouseHovered = true"
    @mouseleave="isMouseHovered = false"
  >
    <slot name="v-nav-menu-header">
      <vertical-nav-menu-header @close-nav-menu="$emit('close-nav-menu')"></vertical-nav-menu-header>
    </slot>

    <!-- Shadow -->
    <div
      :class="{'d-block': shallShadowBottom}"
      class="shadow-bottom"
    />

    <!--    <perfect-scrollbar-->
    <!--      class="ps-nav-menu-items"-->
    <!--      :options="perfectScrollbarOptions"-->
    <!--      @ps-scroll-y="evt => { shallShadowBottom = evt.srcElement.scrollTop > 0 }"-->
    <!--    >-->
    <v-list
      expand
      class="vertical-nav-menu-items"
    >
      <component
        :is="resolveNavItemComponent(item)"
        v-for="item in navMenuItems"
        :key="item.subheader || item.title"
        :item="item"
      />
    </v-list>
    <v-list>
      <v-list-item :to="{ name: 'admin-user-edit', params: {id: userData.id}}">
        <v-list-item-icon class="me-2">
          <v-icon size="25">
            {{ icons.mdiAccountCogOutline }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>
            {{ $t('myAccount') }}
          </v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <v-divider></v-divider>

      <!-- Logout -->
      <v-list-item @click="logoutUser">
        <v-list-item-icon class="me-2">
          <v-icon size="22">
            {{ icons.mdiLogoutVariant }}
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
    <!--    </perfect-scrollbar>-->
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { getCurrentInstance, provide, ref } from '@vue/composition-api'

// SFCs
import VerticalNavMenuHeader
from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-header/VerticalNavMenuHeader.vue'
import VerticalNavMenuSectionTitle
from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-section-title/VerticalNavMenuSectionTitle.vue'
import VerticalNavMenuGroup
from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import VerticalNavMenuLink
from '@core/layouts/components/vertical-nav-menu/components/vertical-nav-menu-link/VerticalNavMenuLink.vue'

// Composable
import { PerfectScrollbar } from 'vue2-perfect-scrollbar'
import { useRouter } from '@core/utils'
import {
  mdiAccountCogOutline,
  mdiAccountOutline, mdiChatOutline,
  mdiCheckboxMarkedOutline, mdiCogOutline, mdiCurrencyUsd,
  mdiEmailOutline, mdiHeartOutline, mdiHelpCircleOutline, mdiLogoutVariant, mdiMenu,
} from '@mdi/js'
import useVerticalNavMenu from '@/@core/layouts/composable/vertical-nav/useVerticalNavMenu'
import { initialAbility } from '@/plugins/acl/config'

// 3rd Party

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuGroup,
    VerticalNavMenuLink,
    VerticalNavMenuSectionTitle,

    // 3rd Party
    PerfectScrollbar,
  },
  props: {
    navMenuItems: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const { resolveNavItemComponent, isMouseHovered } = useVerticalNavMenu()
    provide('isMouseHovered', isMouseHovered)

    const vm = getCurrentInstance().proxy
    const { router } = useRouter()
    const userData = JSON.parse(localStorage.getItem('userData'))

    const logoutUser = () => {
      // Remove userData from localStorage
      // ? We just removed token from localStorage. If you like, you can also make API call to backend to blacklist used token
      localStorage.removeItem('accessToken')

      // Remove userData & Ability from localStorage
      localStorage.removeItem('userData')
      localStorage.removeItem('userAbility')

      // Reset ability
      vm.$ability.update(initialAbility)

      // Redirect to login page
      router.push({ name: 'auth-login' })
    }

    const perfectScrollbarOptions = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    }

    // Shadow bottom is UI specific and can be removed by user => It's not in `useVerticalNavMenu`
    const shallShadowBottom = ref(false)

    provide('openGroups', ref([]))

    return {
      logoutUser,
      userData,
      resolveNavItemComponent,
      perfectScrollbarOptions,
      isMouseHovered,
      shallShadowBottom,
      icons: {
        mdiAccountCogOutline,
        mdiAccountOutline,
        mdiEmailOutline,
        mdiCheckboxMarkedOutline,
        mdiChatOutline,
        mdiCogOutline,
        mdiCurrencyUsd,
        mdiHelpCircleOutline,
        mdiLogoutVariant,
        mdiMenu,
        mdiHeartOutline,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css';
</style>

<style lang="scss">
@import '~vuetify/src/styles/styles.sass';
@import '~@core/preset/preset/mixins.scss';

.app-navigation-menu {
  transition-duration: 0.4s !important;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1), ease !important;
  will-change: box-shadow, transform !important;

  .v-list-item,
  .v-subheader {
    padding: 0 18px 0 22px;
  }

  .v-list-item {
    margin-top: 0.375rem;
  }

  .v-list-group .v-list-group__header .v-list-item__icon.v-list-group__header__append-icon {
    min-width: 24px;
  }

  .v-navigation-drawer__border {
    background-color: transparent !important;
  }

  // &.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover {
  //   @include elevation(9);
  //   @include elevationTransition();
  // }
}

@include app-elevation('app-navigation-menu.v-navigation-drawer--open-on-hover.v-navigation-drawer--is-mouseover', 9);

// Set Perfect Scrollbar Container Height
.vertical-nav-menu-container {
  height: 100%;
  .ps-nav-menu-items {
    height: calc(100% - 57px) !important;
  }

  // menu scroll shadow
  .shadow-bottom {
    display: none;
    position: absolute;
    z-index: 2;
    height: 50px;
    width: 100%;
    left: -6px;
    pointer-events: none;
    margin-top: -0.7rem;
    filter: blur(5px);
  }
}

@include theme--child(vertical-nav-menu-container) using ($material) {
  .shadow-bottom {
    background: linear-gradient(
      map-deep-get($material, 'background') 40%,
      rgba(map-deep-get($material, 'background'), 0.1) 95%,
      rgba(map-deep-get($material, 'background'), 0.05)
    );
    margin-top: -0.5rem;
    filter: blur(4px);
  }
}

@include theme(vertical-nav-menu-items) using ($material) {
  @include ltr() {
    padding-right: 20px !important;
  }
  @include rtl() {
    padding-left: 20px !important;
  }
  @at-root .app-navigation-menu.v-navigation-drawer--mini-variant:not(.v-navigation-drawer--is-mouseover) {
    .vertical-nav-menu-items {
      @include ltr() {
        padding-right: 5px !important;
      }
      @include rtl() {
        padding-left: 5px !important;
      }
    }
  }

  .v-subheader:not(:first-child) {
    margin-top: 18px;
  }

  .v-list-item__icon {
    &:first-child {
      @include ltr() {
        margin: 0 12px 0 0;
      }
      @include rtl() {
        margin: 0 0 0 12px;
      }
      align-self: center;
    }
    &.v-list-group__header__append-icon {
      @include ltr() {
        margin-left: 4px;
      }
      @include rtl() {
        margin-right: 4px;
      }
    }
  }

  // Center align small icon
  .v-list-group__items .v-list-item__icon {
    margin-top: 0px;
    @include ltr() {
      margin-left: 5px;
    }
    @include rtl() {
      margin-right: 5px;
    }
  }

  .v-list-item {
    @include app-states($material);

    height: 44px;
    min-height: 44px;
    color: map-deep-get($material, 'text', 'primary');
    // ? Reason: In collapsed mode due to modified mini width & flex grow icon change position while collapsing the drawer
    justify-content: flex-start !important;

    &.v-list-item--active {
      &:not(.v-list-group__header) {
        color: white;
        // background-image: linear-gradient(98deg, #c48eff, var(--v-primary-base) 94%);
      }
      &.v-list-group__header {
        background-color: rgba(
          map-deep-get($material, 'text', 'primary'),
          map-deep-get($material, 'states', 'selected')
        );
      }
    }

    &.vertical-nav-menu-link {
      .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}
</style>
